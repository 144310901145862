/* Counties.css */
.container {
    padding: 20px;
  }
  
  .list-group-flush {
    margin-top: 10px;
  }
  
  /* Add other styles as needed */
  