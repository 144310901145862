h1 {
  text-align: center;
}

.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.dropdownContainer {
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

/* .container {
    margin: auto;
    align-items: center;
    padding-bottom: 50px;
}

.card-container {
    margin: auto;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
  } */
