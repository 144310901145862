h1 {
  text-align: center;
}

.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bar {
  fill: steelblue; /* This will color the bars steel blue */
}

svg {
  background-color: white; /* Ensures the SVG background is white */
}

.visualization-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  align-items: center; 
  margin-top: 20px;
}

.visualization-heading {
  text-align: center;
  /* margin-bottom: 10px; */
  padding: 10px;
  border: 1px solid #746d6d;
  border-radius: 5px;
  width: auto;
}